import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCategoryLinksContainer,
  StyledLinkContainer,
  StyledCategoryLink,
  StyledLinkImageContainer,
} from './CategoryLinks.style';
import { Text, Image } from '@Omegapoint/components';
import { withTheme } from 'styled-components';

const CategoryLinksComponent = ({ categoryLinks, theme }) => {
  return (
    <StyledCategoryLinksContainer>
      {categoryLinks
        ? categoryLinks.map((d, index) => {
            // Match active url later
            return d.show ? (
              <StyledLinkContainer key={index} active={d.isCurrentCategory}>
                <StyledCategoryLink to={d.path}>
                  {process.env.GATSBY_ENABLE_ASSORTMENT_CATEGORY_IMGS !==
                    'false' && (
                    <StyledLinkImageContainer>
                      <Image
                        src={d.image}
                        asBackgroundImage={false}
                        height={'25px'}
                        width={'25px'}
                      />
                    </StyledLinkImageContainer>
                  )}
                  <Text color={theme.Color.white} variant="p">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: d.title,
                      }}
                    />
                  </Text>
                </StyledCategoryLink>
              </StyledLinkContainer>
            ) : null;
          })
        : null}
    </StyledCategoryLinksContainer>
  );
};

CategoryLinksComponent.propTypes = {
  className: PropTypes.string,
  categoryLinks: PropTypes.any,
};

export default withTheme(CategoryLinksComponent);
