import styled from 'styled-components/macro';
import Link from 'gatsby-link';

export const StyledCategoryLinksContainer = styled.ul`
  columns: 2;
  top: 0;
  justify-self: center;
  font-size: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  column-count: 2;

  @media ${({ theme }) => theme.Breakpoints.md} {
    padding-left: 0px;
    padding-top: -5px;
    padding-bottom: -5px;
    padding-right: 0px;
    font-size: inherit;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const StyledLinkContainer = styled.li`
  background-color: ${({ active, theme: { Color } }) =>
    active ? Color.grey80 : 'inherit'};
  &:hover {
    background-color: ${({ theme }) => theme.Color.grey80};
    transition: 0.2s;
  }
  transition: 0.2s;
  @media ${({ theme }) => theme.Breakpoints.xl} {
    flex: 0 0 50%;
    display: flex;
  }
`;

export const StyledCategoryLink = styled(Link)`
  color: ${({ theme }) => theme.Color.white};
  padding: 5px;
  text-decoration: none;
  display: inline-flex;
  width: 100%;
  background-color: ${({ active, theme: { Color } }) =>
    active ? Color.grey80 : 'inherit'};
  align-items: center;
`;

export const StyledLinkImageContainer = styled.div`
  margin-right: 4px;
  min-width: 25px;
  height: 25px;
  width: 25px;
  @media ${({ theme }) => theme.Breakpoints.xl} {
    margin-right: 13px;
  }
`;
