import styled, { css } from 'styled-components/macro';
import { Text, Button } from '@Omegapoint/components';
import { Col } from '@Omegapoint/components/layout';
import Link from 'gatsby-link';
import { AccordionContainer } from '@components/Accordion';

export const StyledCategoryFilterSection = styled.section`
  background-color: ${({ theme }) => theme.Color.black};
  padding: ${({ theme }) => theme.Variables.gutter} 0;
`;

export const StyledColumn = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const StyledColWithDivider = styled(StyledColumn)`
  border-right: 1px solid ${({ theme }) => theme.Color.grey80};
  border-left: 1px solid ${({ theme }) => theme.Color.grey80};
`;

export const StyledButton = styled(Button)`
  width: 185px;
  max-height: 60px;
  min-height: 60px;
  justify-self: flex-end;
  margin-left: -${({ theme }) => theme.Variables.gutter};
  display: none;
  margin-top: auto;
  @media ${({ theme }) => theme.Breakpoints.md} {
    margin-left: 0px;
    display: block;
  }
`;

export const StyledImageContainer = styled.div`
  width: 45%;
  max-width: 250px;
  align-self: flex-end;
  justify-self: flex-end;
  @media ${({ theme }) => theme.Breakpoints.md} {
    align-self: center;
  }
`;

export const StyledIcon = styled.div`
  padding: 13px;
  width: 20px;
  height: 20px;
  padding-right: 13px !important;
`;

export const StyledHeadline = styled(Text)`
  font-size: 25px;
  padding-left: ${({ theme }) => theme.Variables.gutter};
  padding-top: ${({ theme }) => theme.Variables.gutter};
  &:last-child {
    padding-bottom: 0px;
  }
  @media ${({ theme }) => theme.Breakpoints.md} {
    font-size: 30px;
    padding-left: 0;
    padding-top: 0;
  }
  @media ${({ theme }) => theme.Breakpoints.xl} {
    font-size: 30px;
    padding: 0;
  }
`;

export const StyledFilterHeadline = styled(StyledHeadline)`
  padding: 0px;
  @media (max-width: ${({ theme }) => theme.Sizes.md}px) {
    margin-bottom: unset;
  }
`;

export const StyledMiddleContent = styled.section`
  width: ${({ narrow }) => narrow && '33%'};
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  display: space-between;
`;

export const StyledMiddleContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  justify-content: space-between;
`;

export const HeadlineContainer = styled.span(
  ({ theme: { Variables, Breakpoints } }) => css`
    padding: ${Variables.gutter} 0;
    @media ${Breakpoints.md} {
      padding: 0;
    }
  `
);

export const StyledHr = styled.hr`
  width: 99%;
  border: 1px solid ${({ theme }) => theme.Color.grey70};
  margin-top: -50px;
  @media ${({ theme }) => theme.Breakpoints.md} {
    display: none;
  }
`;

export const CtaLink = styled(Link)`
  display: none;
  margin-top: auto;
  @media ${({ theme }) => theme.Breakpoints.md} {
    margin-left: 0px;
    display: block;
  }
`;

export const FilterAccordionContainer = styled(AccordionContainer)`
  margin-top: ${({ theme }) => theme.Variables.gutter};
`;
