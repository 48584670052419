import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '@Layouts/Default';
import Breadcrumbs from '@components/Breadcrumbs';
import { Container, Row, Col } from '@Omegapoint/components/layout';
import CategoryFilter from '@components/CategoryFilter';
import ArticleList from '@components/ArticleList';
import { Variables } from '@styles/global';
import useStore from '@store';
import LoginModal from '@Omegapoint/components/Auth/LoginModal';
import Spinner from '@components/atoms/Spinner';
import { PageBuilderContent } from '@Omegapoint/components/pageBuilderComponents';

const CategoryPage = (props) => {
  const { data, location, pageContext } = props;
  const auth = useStore((state) => state.auth);
  const assortment = useStore((state) => state.assortment);
  const assortmentActions = useStore((state) => state.assortmentActions);

  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    if (assortment.pageCategory !== pageContext.entityId) {
      assortmentActions.setPageCategory(pageContext.entityId);
      assortmentActions.setCategoryFilters([]);
    } else if (assortment.categoryFilters !== activeFilters) {
      setActiveFilters(assortment.categoryFilters);
    }
  }, []);

  const setFilterStorage = (filters) => {
    setActiveFilters(filters);
    assortmentActions.setCategoryFilters(filters);
  };

  if (typeof data === 'undefined') return null;

  const categoryFilters = data.chCategory.subCategories.map((subCategorie) => {
    return {
      title: subCategorie.title,
      id: subCategorie.entityId,
    };
  });

  const categoryLinks = data.allSitePage.edges
    .map((edge) => {
      const { node } = edge;
      var chCategory = data.allChCategory.edges.find((edge) => {
        return edge.node.entityId === node.pageContext.entityId;
      });
      return {
        title: node.pageContext.title,
        path: node.path,
        isCurrentCategory:
          node.pageContext.entityId === props.pageContext.entityId,
        image: chCategory?.node?.mainImage?.imageFormats?.icon,
        sortOrder: chCategory?.node?.sortOrder,
        show: chCategory?.node?.locked
          ? chCategory?.node?.costumerAccess?.some(
              (x) => x.trim() == auth.user?.accountNumber
            )
          : true,
      };
    })
    .sort((a, b) => (parseInt(a.sortOrder) > parseInt(b.sortOrder) && 1) || -1);

  const hasRteChild = data?.cmsPages?.Items.Content.Sections?.map((section) =>
    section.Rows?.some((row) =>
      row.Areas?.some((area) =>
        area.Blocks?.some((block) => block.Alias === 'rte')
      )
    )
  ).some((result) => result);

  return (
    <DefaultLayout
      path={props.path}
      culture={props.pageContext.culture}
      canonical={data.chCategory.canonical ?? props.path}
      title={data.chCategory.title}
      description={data.chCategory.description}
    >
      {process.env.GATSBY_REQUIRE_LOGIN === 'true' && auth.status !== 'in' ? (
        <>
          {auth.status === 'loading' || auth.status === 'none' ? (
            <Spinner />
          ) : (
            <LoginModal culture={props.pageContext.culture} />
          )}
        </>
      ) : (
        <>
          <Container gutter="20">
            <Row gutters={Variables.gutter}>
              <Col>
                <Breadcrumbs
                  parts={data.chCategory.breadcrumb}
                  path={location.pathname}
                />
              </Col>
            </Row>
          </Container>

          <CategoryFilter
            categoryFilters={categoryFilters}
            categoryTitle={data.chCategory?.title}
            categoryDescription={data.chCategory?.description}
            categoryImage={data.chCategory?.mainImage?.imageUrl}
            categoryLinks={categoryLinks}
            activeFilters={activeFilters}
            setActiveFilters={setFilterStorage}
            ctaLink={data?.cmsPages?.Items?.CtaLink?.Url}
          />
          <Container>
            <Row>
              <Col>
                <ArticleList
                  categoryIds={[pageContext.entityId]}
                  subCategoryIds={activeFilters}
                >
                  {data?.cmsPages?.Items?.Content && (
                    <div
                      style={
                        hasRteChild
                          ? {
                              marginTop: '-50px',
                              marginBottom: '-50px',
                            }
                          : {}
                      }
                    >
                      <PageBuilderContent
                        content={data?.cmsPages?.Items?.Content}
                      ></PageBuilderContent>
                    </div>
                  )}
                </ArticleList>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </DefaultLayout>
  );
};

export default CategoryPage;

export const query = graphql`
  query CategoryPageQuery(
    $entityId: String!
    $culture: String!
    $marketEntityId: String!
  ) {
    cmsPages(Items: { Category: { marketEntityId: { eq: $marketEntityId } } }) {
      Items {
        Content {
          ...PageBuilderContent
        }
        CtaLink {
          Name
          Url
        }
      }
    }
    allSitePage(
      filter: {
        context: { isCategory: { eq: true }, culture: { eq: $culture } }
      }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }
    allChCategory(filter: { market: { countryCode: { eq: $culture } } }) {
      edges {
        node {
          entityId
          sortOrder
          locked
          costumerAccess
          mainImage {
            imageUrl
            imageFormats {
              icon
            }
          }
        }
      }
    }
    chCategory(
      market: { countryCode: { eq: $culture } }
      entityId: { eq: $entityId }
    ) {
      title
      description
      breadcrumb
      mainImage {
        imageUrl
        imageFormats {
          icon
        }
      }
      subCategories {
        title
        entityId
        products {
          title
          path
        }
      }
    }
  }
`;
